import {
  ExclamationCircleOutlined,
  LinkOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Col, Modal, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import arrowLeft from "../../assets/icons/ri-arrow-drop-left-line.png";
import "../../assets/css/bootstrap.min.css";
import utils from "../../utility/utils";
import "./style.css";
import { network } from "../../utility/network";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ICLeftWhite from "../../assets/icons/arrow-left-white.png";
import "./style.css";

export const RightSide = ({ data }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [tempStyle, setTempstyle] = useState({
    transform: "rotate(0deg)",
    width: "15px",
  });
  const [tokenShare, setTokenShare] = useState("");
  const [isShareModalShow, setisShareModalShow] = useState(false);
  const [modalErr, setModalErr] = useState(false);
  const { confirm } = Modal;
  const navigate = useNavigate();

  const { t } = useTranslation();

  const showPromiseConfirm = (message) => {
    confirm({
      title: t("Gagal!"),
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk() {},
      onCancel() {},
    });
  };

  const hendleBagikan = () => {
    if (!data) {
      showPromiseConfirm(t("Data tidak lengkap"));
      return;
    }

    let form = {
      jumlah_kebutuhan: Math.ceil(data.res).toString(),
      share_image_id: utils.fetchLocalStorage("_share_image_id"),
      panjang_ruangan: data.panjang,
      lebar_ruangan: data.lebar,
      waste: data.waste,
      besar_perbox: data.box,
    };

    // console.log(form);

    // return;

    let formShare = {
      image_url: utils.fetchLocalStorage("_image"),
      product_id: utils.fetchLocalStorage("_product_id"),
      asset_id: utils.fetchLocalStorage("asset_id"),
      url_layout: utils.fetchLocalStorage("select_product"),
      panjang_ruangan: data.panjang,
      lebar_ruangan: data.lebar,
      besar_perbox: data.box,
      waste: data.waste,
      jumlah_kebutuhan: Math.ceil(data.res).toString(),
      product_name: utils.fetchLocalStorage("product_name"),
      layout_name: utils.fetchLocalStorage("layout_name"),
      kategori: data.kategori,
    };

    if (utils.fetchLocalStorage("is_upload")) {
      formShare.is_upload = 1;
    }

    network.post(
      `create-share-link`,
      formShare,
      (res) => {
        setTokenShare(res.data.ref);
        setisShareModalShow(true);
        utils.saveLocalStoreage("_share_image_id", res.data.id);
      },
      (err) => {},
      () => {}
    );

    // network.post("detail-share/create", form, (res) => {
    //   utils.saveLocalStoreage("_ref_detail_share", res.data.ref);
    //   setTokenShare(res.token_room_image);
    //   setisShareModalShow(true);
    // });
  };

  return (
    <>
      {/* Modal Error */}
      <Modal
        onCancel={() => setModalErr(false)}
        footer={false}
        className="modal__experiment"
        onOk={() => setModalErr(false)}
        visible={modalErr}
      >
        {!data ? (
          <h4 className="fontw-6 text__black">
            {t("Isi data terlebih dahulu")}
          </h4>
        ) : (
          <h4 className="fontw-6 text__black">
            {t("Image ruangan tidak di temukan")}
          </h4>
        )}
      </Modal>
      {/* Modal Error */}

      {/* Modal share */}
      <Modal
        onCancel={() => setisShareModalShow(false)}
        footer={false}
        className="modal__experiment"
        onOk={() => setisShareModalShow(false)}
        visible={isShareModalShow}
      >
        <h4 className="fontw-6 text__black">
          {t("Bagikan hasil eksperimen!")}
        </h4>
        <div className="exp__copy__link fontw-6">
          {window.location.host + "/share?token=" + tokenShare}
        </div>

        <div className="mt-3 text__black">{t("Atau bagikan melalui :")}</div>
        <div className="d-flex mt-1">
          <FacebookShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <FacebookIcon className="exp__social__btn" />
          </FacebookShareButton>
          <WhatsappShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <WhatsappIcon className="exp__social__btn" />
          </WhatsappShareButton>
          <TwitterShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <TwitterIcon className="exp__social__btn" />
          </TwitterShareButton>
          <LineShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <LineIcon className="exp__social__btn" />
          </LineShareButton>

          <Tooltip placement="top" title="Tersalin!" trigger="click">
            <div
              className="exp__social__btn"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.host}/share?token=${tokenShare}`
                );
              }}
            >
              <LinkOutlined />
            </div>
          </Tooltip>
        </div>
      </Modal>

      <div className="gutter-row" md={6} xs={24}>
        <div className="cal__box__right text-left">
          <div className="d-flex justify-content-between">
            <h4 className="text__black fontw-9 mb-3">{t("Rincian")}</h4>
          </div>

          <div className="">
            {/* <div className="collapseaa-media d-flex justify-content-between align-items-center">
              <h4 className="text__black fontw-9">Detail </h4>
            </div> */}
            {/* collapse */}
            <Collapse isOpened={openCollapse}>
              <table className="mb-4 custom_p">
                <tr>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="">{t("Kategori")} </div>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="tab"> :</div>{" "}
                  </td>
                  <td className="pl-2">
                    {data != null ? data.kategori : "-"} <br />
                    {utils.fetchLocalStorage("product_name")} -{" "}
                    {utils.fetchLocalStorage("layout_name")}
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="">{t("Luas Ruangan")} </div>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="tab"> :</div>{" "}
                  </td>
                  <td className="pl-2">
                    {data != null ? data.luas : "-"} m<sup>2</sup>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="">{t("Besar Persegi per Box")} </div>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="tab"> :</div>{" "}
                  </td>
                  <td className="pl-2">{data != null ? data.box : "-"} box</td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="">{t("Waste")} </div>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="tab"> :</div>{" "}
                  </td>
                  <td className="pl-2">
                    {data != null ? data.waste + "%" : "-"}
                  </td>
                </tr>
              </table>
            </Collapse>
            {/* endcollapse */}
          </div>

          <div className="d-flex justify-content-between">
            <p className="text__black custom_p" style={{ marginBottom: 0 }}>
              <strong>{t("Estimasi")}</strong> {t(" jumlah yang dibutuhkan")}

            </p>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (openCollapse) {
                  setOpenCollapse(false);
                  setTempstyle({ transform: "rotate(0deg)", width: "15px" });
                } else {
                  setTempstyle({ transform: "rotate(180deg)", width: "15px" });
                  setOpenCollapse(true);
                }
              }}
            >
              <p
                className="text__black mr-3 custom_p"
                style={{ marginBottom: 0, cursor: "pointer" }}
              >
                {openCollapse ? t("Sembunyikan") : t("Tampilkan")}
              </p>
              <div className="">
                <img
                  src={ICLeftWhite}
                  className="img-fluid"
                  alt="taco image"
                  style={tempStyle}
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <h2
              className=" "
              style={{ color: "#E84A24", fontWeight: 800, fontSize: "40px" }}
            >
              {data != null ? Math.ceil(data.res) + " box" : "-"}
            </h2>
            {utils.fetchLocalStorage("image_kategori_cal") ? (
              <img
                src={utils.fetchLocalStorage("image_kategori_cal")}
                alt="taco empty"
                className="img-fluid"
              />
            ) : (
              <></>
            )}
          </div>

          <div
            onClick={hendleBagikan}
            className="text__black cursor__pointer d-flex flex-row  justify-content-between  fontw-6 home__outline mb-0 py-1  d-inline mt-2"
          >
            <div className="custom_p">
              <p className="text__black">
                {data != null ? data.panjang : "-"}m x{" "}
                {data != null ? data.lebar : "-"}m ={" "}
                {data != null ? data.luas : "-"} m<sup>2</sup>
              </p>
              <p className="text__black">
                {data != null ? data.divisor : "-"} x (
                {data != null ? data.luas : "-"} m<sup>2</sup> /{" "}
                {data != null ? data.box : "-"} m<sup>2</sup>) =
                <br />
                {data != null ? data.res.toFixed(2) : "-"} ={" "}
                {data != null ? Math.ceil(data.res) : "-"} Box
              </p>
              <h6 className="text__black">
                <span className="tag__span"></span>
              </h6>
            </div>
            <div className="d-md-flex flex-column align-items-center d-none ">
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.7107 37.648C35.1819 39.216 33.345 40 31.2 40C29.055 40 27.2194 39.216 25.6932 37.648C24.1644 36.0827 23.4 34.2 23.4 32V31.4L12.9675 26C12.2525 26.6 11.4569 27.084 10.5807 27.452C9.7019 27.8173 8.775 28 7.8 28C5.655 28 3.8194 27.216 2.2932 25.648C0.764399 24.0827 0 22.2 0 20C0 17.8 0.764399 15.916 2.2932 14.348C3.8194 12.7827 5.655 12 7.8 12C8.775 12 9.7019 12.1827 10.5807 12.548C11.4569 12.916 12.2525 13.4 12.9675 14L23.4 8.6V8C23.4 5.8 24.1644 3.91733 25.6932 2.352C27.2194 0.783999 29.055 0 31.2 0C33.345 0 35.1819 0.783999 36.7107 2.352C38.2369 3.91733 39 5.8 39 8C39 10.2 38.2369 12.0827 36.7107 13.648C35.1819 15.216 33.345 16 31.2 16C30.225 16 29.2994 15.8173 28.4232 15.452C27.5444 15.084 26.7475 14.6 26.0325 14L15.6 19.4V20.6L26.0325 26C26.7475 25.4 27.5444 24.916 28.4232 24.548C29.2994 24.1827 30.225 24 31.2 24C33.345 24 35.1819 24.7827 36.7107 26.348C38.2369 27.916 39 29.8 39 32C39 34.2 38.2369 36.0827 36.7107 37.648Z"
                  fill="#1C1B1F"
                />
              </svg>
              <div
                className="mt-2"
                style={{ fontWeight: 700, fontSize: "14px" }}
              >
                {t("Bagikan")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
